import React from 'react'
import Helmet from 'react-helmet';
import Layout from '../components/layout'
import Header from '../components/header'
import '../components/blog.css'

const AffDisclosure = () => (
    <Layout>
        <Helmet title="Affiliate Disclosure" />
        <Header siteTitle={'Reactgo'} />
        <div className="disclosure">
            <div>
                <h1>Affiliate Disclosure</h1>
                <p>
                    This site contains affiliate links from amazon, udemy if you buy any course by
                    using those links i earn some money, which doesn't costs you
                    anything more,because those courses are the same price if you
                    buy without using an affiliate link.
                </p>
                <p>
                    The affiliate links are some courses, books which i use to
                    improve my skills.
                </p>
                <blockquote>
                    <p style={{ fontSize: "21px" }}>
                        Note: I wouldn't recommend those courses that wastes your time
                        and money.
                    </p>
                </blockquote>
            </div>
        </div>
    </Layout>
)

export default AffDisclosure